/* Features Section Styles */
.features-container {
    padding: clamp(20px, 5vw, 60px);
}
  
.feature-screenshot {
    max-width: 100%;
    height: auto;
    width: 500px;
    object-fit: contain;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-screenshot-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
   
}

.feature-screenshot-container:hover .feature-screenshot {
    transform: scale(1.05);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.feature-content-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 20px;
}

.feature-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
    transition: transform 0.3s ease, color 0.3s ease;
}

.feature-icon:hover {
    transform: scale(1.2);
    color: #287233;
}

.feature-title {
    margin-bottom: 16px;
    font-size: clamp(20px, 2vw, 28px);
    font-weight: bold;
    color: #333;
}

.feature-description {
    font-size: clamp(14px, 1.5vw, 18px);
    line-height: 1.75;
    color: #555;
}

.feature-divider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    position: relative;
}

.feature-divider-line {
    height: 3px;
    background: linear-gradient(90deg, 
        rgba(40, 114, 51, 0),
        rgba(40, 114, 51, 0.8) 50%,
        rgba(40, 114, 51, 0)
    );
    flex-grow: 1;
    max-width: 400px;
    transition: all 0.3s ease;
}

.feature-divider-center {
    position: relative;
    width: 40px;
    height: 40px;
    margin: 0 20px;
    transform: rotate(45deg);
    transition: all 0.3s ease;
}

.feature-divider-center::before {
    content: '';
    position: absolute;
    inset: 4px;
    border: 2px solid #287233;
    opacity: 0.6;
}

.feature-divider-center::after {
    content: '';
    position: absolute;
    inset: 12px;
    background-color: #287233;
    opacity: 0.8;
}

.feature-divider:hover .feature-divider-center {
    transform: rotate(135deg);
}

.feature-divider:hover .feature-divider-line {
    height: 2px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .feature-content-container {
        align-items: flex-start;
        text-align: left;
    }

    .feature-screenshot {
        width: 100%;
        max-width: 400px;
    }

    .feature-section {
        margin-bottom: 40px;
    }

    .feature-row {
        padding: 20px 0;
    }
}
