  
.anonymous_container_section {
    /**/
}

.anonymous_form_container_section {
    max-width: 600px;
}
    
.brand-color {
    /* color: #287233;   */
}

.brand-bg {
    /* background-color: #287233;   */
}

.custom-landing-nav {
    border-bottom: 2px solid #287233;
}

.bullet-list li::before {
    content: "•";
    color: #0056b3; /* Change the color to match your brand */
    position: absolute;
    left: 0;
}