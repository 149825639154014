/* Hero Section Styles */
.hero-section {
    background-color: #287233;
    padding: 2rem 0;
    position: relative;
    overflow: hidden;
}

/* Contenedor principal */
.hero-main {
    text-align: center;
    padding: 1rem 0 2rem;
}
/* Estilos del grupo de título */
.hero-title-group {
    display: inline-flex;
    align-items: center;
    gap: 1.5rem;
    background: rgba(255, 255, 255, 0.12);
    padding: 0.75rem 2rem;
    border-radius: 12px;
    position: relative;
    backdrop-filter: blur(8px);
    margin: 0 1rem;
}

/* Título principal */
.hero-title {
    color: white;
    margin: 0;
    font-weight: 500;
    letter-spacing: 0.5px;
}
  
/* Nombre de la aplicación */
.hero-app-name {
    position: relative;
    font-size: 1.5rem;
    font-weight: 600;
    /* Gradiente de color más sofisticado */
    background: linear-gradient(135deg, #FFD700 0%, #FFA726 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}



/* Responsive */
@media (max-width: 768px) {
    .hero-title-group {
        flex-direction: column;
        gap: 0.5rem;
        padding: 1rem;
    }

    .hero-title {
        font-size: 1.4rem;
        text-align: center;
    }

    .hero-app-name {
        font-size: 1.2rem;
        padding-left: 0;
    }

    .hero-app-name::before {
        display: none;
    }
}

/* Contenedor secundario */
.hero-secondary {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 0 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.hero-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.hero-subtitle {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.2rem;
    margin: 0;
    line-height: 1.5;
}

.phrase-card {
    background: rgba(40, 114, 51, 0.08);
    border-radius: 12px;
    padding: 1.5rem;
    margin-top: 1rem;
    transition: transform 0.3s ease;
}

.phrase-card:hover {
    transform: translateY(-2px);
}

.phrase-text {
    font-size: 1.25rem;
    line-height: 1.6;
    color: #2c2c2c;
    font-weight: 500;
    margin: 0;
    letter-spacing: 0.2px;
}

.hero-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-image {
    max-width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease;
}

.hero-image:hover {
    transform: translateY(-4px);
}

.spinner-container {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Responsive */
@media (max-width: 992px) {
    .hero-secondary {
        grid-template-columns: 1fr;
        gap: 1.5rem;
        padding: 0 1rem;
    }

    .hero-image-container {
        order: -1;
    }

    .hero-image {
        max-width: 80%;
    }
}

@media (max-width: 768px) {
    .hero-title-group {
        flex-direction: column;
        gap: 0.5rem;
        padding: 1rem;
    }

    .hero-app-name {
        border-left: none;
        padding-left: 0;
    }

    .hero-title {
        font-size: 1.8rem;
    }

    .hero-app-name {
        font-size: 1.6rem;
    }

    .hero-image {
        max-width: 100%;
    }
}

.main-section {
    padding: 30px 20px;
    background: linear-gradient(to bottom, #ffffff, #f8f9fa);
    min-height: 70vh;
    display: flex;
    flex-direction: column;
}

.main-content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    padding: 10px 0;
}

.title-group {
    margin-bottom: 25px;
}

.main-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
}

.secondary-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
    padding: 5px;
}

.content-wrapper {
    flex: 1;
    max-width: 450px;
}

.subtitle {
    font-size: 1.8rem;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 1.5rem;
    letter-spacing: -0.5px;
}

.subtitle-small {
    font-size: 1.2rem;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 1.5rem;
    letter-spacing: -0.5px;
}

.image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 450px;
}

.main-image {
    width: 100%;
    height: auto;
    max-height: 350px;
    object-fit: contain;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.phrase-card {
    background: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    transition: transform 0.3s ease;
}

.phrase-text {
    color: #555;
    font-size: 1rem;
    line-height: 1.4;
    margin: 0;
}

@media (max-width: 992px) {
    .secondary-content {
        gap: 15px;
        padding: 5px;
    }
}

@media (max-width: 768px) {
    .main-section {
        padding: 15px 10px;
    }

    .secondary-content {
        flex-direction: column-reverse;
        text-align: center;
    }

    .content-wrapper {
        max-width: 100%;
    }

    .image-container {
        max-width: 100%;
        padding: 0;
    }

    .main-image {
        max-height: 250px;
    }

    .main-title {
        font-size: 2rem;
    }

    .subtitle {
        font-size: 1.3rem;
    }

    .subtitle-small {
        font-size: 1.2rem;
    }
}

.decorative-divider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
    position: relative;
}

.divider-line {
    height: 5px;
    background: linear-gradient(90deg, 
        rgba(40, 114, 51, 0),
        rgba(40, 114, 51, 0.8) 50%,
        rgba(40, 114, 51, 0)
    );
    flex-grow: 1;
    max-width: 400px;
    transition: all 0.3s ease;
}

.divider-center {
    position: relative;
    width: 40px;
    height: 40px;
    margin: 0 20px;
    transform: rotate(45deg);
    transition: all 0.3s ease;
}

.divider-center::before {
    content: '';
    position: absolute;
    inset: 4px;
    border: 2px solid #287233;
    opacity: 0.6;
}

.divider-center::after {
    content: '';
    position: absolute;
    inset: 12px;
    background-color: #287233;
    opacity: 0.8;
}

.decorative-divider:hover .divider-center {
    transform: rotate(135deg);
}

.decorative-divider:hover .divider-line {
    height: 3px;
}

.store-buttons-container {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 0.5rem;
    margin-right: 2rem;
}

.apple-store-badge {
    height: 40px;
    width: auto;
}

.google-play-badge {
    height: 40px;
    width: auto;
}

/* Para dispositivos móviles */
@media (max-width: 768px) {
    .store-buttons-container {
        align-items: center;
        margin-right: 1rem;
    }
}