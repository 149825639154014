.fixed-list-header {
    
    top: 45px;
    z-index: 1;
    margin: 0px -10px;
    transition: 0.1s linear;
}

.before-fixed-list-header {
   
    top: 45px;
    z-index: 1;
}