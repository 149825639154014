.custom-field-required {
    border-left: 3px solid red!important;
    border-radius: 5px;
}

.custom-selector-invalid, .custom-selector-invalid:focus {
    border: 1px solid red;
    border-radius: 5px;
    margin-left: -1px;
}
.custom-selector-invalid:focus {
    border: 1px solid red;
    border-radius: 5px;
    margin-left: 10px;
}

.custom-column-size-xsm {
    width: 120px;
}

.custom-column-size-sm {
    width: 180px;
}

.custom-column-size-md {
    width: 300px;
}

.custom-column-size-lg {
    width: auto;
}

.currency-format {
    font-weight: 500;
    font-family: monospace;
}