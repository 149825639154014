.watermark {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    background-image: url('../../logo.png'); /* Reemplaza 'logo.png' con la ruta de tu logo */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0.1;
    z-index: -1;
    transform: translate(-50%, -50%);
}

.home-default-container {
    position: relative;
    min-height: 800px;
}