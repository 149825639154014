.no-data-container {
  }
  
  .no-data-icon {
    font-size: 48px;
    color: #6c757d;
  }
  
  .no-data-text {
    color: #495057;
  }
  
  .no-data-message {
    color: #6c757d;
  }