.navbar {
    padding: 1rem 2rem;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    background-color: #287233 !important;
}

.navbar-brand span {
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: 1px;
    color: white;
}

.navbar .nav-link,
.navbar .nav-link.active,
.navbar .nav-link:focus,
.navbar .nav-link:active,
.navbar .nav-link:visited {
    font-weight: 600 !important;
    padding: 0.6rem 0.5rem !important; 
    color: #FFFFFF !important;
    font-size: 1rem;
    position: relative;
    text-decoration: none;
    margin: 0 0.8rem;
    letter-spacing: 0.5px;
}

.navbar .nav-link:hover,
.navbar .nav-link.active:hover {
    color: #FFFFFF !important;
}

.nav-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #FFFFFF;
    transition: width 0.3s ease;
    transform: none;
}

.nav-link:hover::after {
    width: 100%;
}

.menu-left {
    justify-content: flex-start; /* Alinea el menú a la izquierda */
}

.navbar .container-fluid {
    justify-content: space-between; /* Espaciado entre logo y botones */
}

.navbar .nav-link {
    color: white !important;
    text-decoration: none;
    margin-right: 8px;
}

.btn-custom-outline {
    color: #287233 !important;
    background-color: white !important;
    border: none;
    padding: 0.7rem 2rem;
    font-weight: 600;
    font-size: 1.1rem;
    border-radius: 25px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.2s ease;
}

.btn-custom-outline:hover {
    transform: translateY(-1px);
}

@media (max-width: 768px) {
    .navbar {
        padding: 0.8rem 1rem;
    }
    
    .navbar-brand span {
        font-size: 1.5rem;
    }

    .btn-custom-outline {
        padding: 0.5rem 1.5rem;
        font-size: 1rem;
    }
}