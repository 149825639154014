.tree-list-column {
    width: 175px;
}

.table-tree-header {
    margin-bottom: 0;
}

.basic-tree-item-hover-move {
    color: transparent;
}

.basic-tree-item-move-row .basic-tree-item-name {
    border-bottom: 2px solid green;
}

.basic-tree-item-move-row-leaf .basic-tree-item-name {
    border-bottom: 2px solid red;
}

.basic-tree-item-move-container {
    color: grey;
}

.basic-tree-item-moving-row {
    opacity: 0.2;
}

.tree-index-item-label .search-color {
    background-color: #c0c0c0
}

.custom-tree-index {
    padding-bottom: 10px;
} 
 