#chart-editor-properties {
    width: 400px;
}

.canvasContainer canvas {
    margin: 0 auto;
}

.canvasContainer {
    padding: 20px 5px;
}

.chart-container {
    max-height: 500px;
}
 