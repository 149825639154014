.lg-glossary-container {
    padding: 4rem 2rem;
}
  
.lg-section-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: linear-gradient(90deg, #00573d 0%, #00825c 100%);
    border-radius: 2px;
}

.lg-glossary-slider {
    max-width: 900px;
    margin: 0 auto;
    padding: 0 20px;
}

.lg-term-card {
    background: white;
    border-radius: 15px;
    padding: 2.5rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
    margin: 15px;
    min-height: 320px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.lg-term-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.12);
}

.lg-term-title {
    color: #00573d;
    font-size: 2rem;
    margin-bottom: 1.8rem;
    text-align: center;
    font-weight: 700;
    position: relative;
    padding-bottom: 12px;
}

.lg-term-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 3px;
    background: linear-gradient(90deg, #00825c 0%, #00a676 100%);
    border-radius: 2px;
}

.lg-term-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.lg-term-section h4 {
    color: #00573d;
    font-size: 1.3rem;
    margin-bottom: 0.8rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.lg-term-section h4::before {
    content: '•';
    margin-right: 8px;
    color: #00825c;
    font-size: 1.5rem;
}

.lg-term-section p {
    color: #424242;
    font-size: 1.1rem;
    line-height: 1.7;
    font-weight: 400;
    margin-left: 20px;
}

/* Slick carousel custom styles */
.lg-glossary-slider .slick-prev,
.lg-glossary-slider .slick-next {
    z-index: 1;
    width: 40px;
    height: 40px;
}

.lg-glossary-slider .slick-prev {
    left: -5px;
}

.lg-glossary-slider .slick-next {
    right: -5px;
}

.lg-glossary-slider .slick-prev:before,
.lg-glossary-slider .slick-next:before {
    font-size: 40px;
    color: #00573d;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.lg-glossary-slider .slick-prev:hover:before,
.lg-glossary-slider .slick-next:hover:before {
    opacity: 1;
}

.lg-glossary-slider .slick-dots {
    bottom: -45px;
}

.lg-glossary-slider .slick-dots li button:before {
    font-size: 12px;
    color: #00573d;
    opacity: 0.5;
    transition: all 0.3s ease;
}

.lg-glossary-slider .slick-dots li.slick-active button:before {
    opacity: 1;
    transform: scale(1.2);
}

/* Media Queries */
@media (max-width: 768px) {
    .lg-section-title {
        font-size: 2.2rem;
    }

    .lg-term-card {
        padding: 1.8rem;
    }

    .lg-term-title {
        font-size: 1.7rem;
    }

    .lg-term-section h4 {
        font-size: 1.2rem;
    }

    .lg-term-section p {
        font-size: 1rem;
    }
} 