/* authenticated-welcome.css */

.authenticated-welcome .custom-header {
    background-color: #287233;
    color: white;
    padding: 15px;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.authenticated-welcome .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.authenticated-welcome .description {
    margin-bottom: 20px;
    font-size: 16px;
    color: #666;
}

.authenticated-welcome .section {
    margin-bottom: 30px;
}

.authenticated-welcome .section-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #287233;
}

.authenticated-welcome .section-description {
    font-size: 16px;
    margin-bottom: 15px;
    color: #444;
}

.authenticated-welcome .welcome-option,
.authenticated-welcome .help-option {
    margin-bottom: 15px;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.authenticated-welcome .welcome-option:hover,
.authenticated-welcome .help-option:hover {
    background-color: rgba(40, 114, 51, 0.1);
    transform: scale(1.02);
}

.authenticated-welcome .card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.authenticated-welcome .icon-green {
    color: #287233;
}

.authenticated-welcome .text-container {
    flex: 1;
    margin-left: 15px;
}

.authenticated-welcome .option-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #287233;
}

.authenticated-welcome .option-description {
    font-size: 14px;
    color: #666;
}

.authenticated-welcome .disabled {
    pointer-events: none;
    opacity: 0.5;
}