td.table-cell-preview {
    width: 40px;
} 

.table-scroll-removed::-webkit-scrollbar {
    display: none; 
}

.listview-nav a, .listview-nav a:hover, .listview-nav i{
    color: black
}

.listview .pin {
    cursor: pointer;
}

.list-item-preview:hover {
    color: #287233;
}