html, body {
    overflow-x: hidden; /* Elimina el scroll horizontal */
    width: 100%; /* Asegura que el ancho de la página se ajuste al ancho de la ventana */
}

body {
    font-family: 'Open Sans', sans-serif;
    color: #333;
    background-color: #f4f4f4;
    font-size: 16px;
}
 
 
/* styles.css 
.application-mobile,
.application-mobile .form-control,
.application-mobile .dropdown-toggle,
.application-mobile .form-select,
.application-mobile .btn-sm,
.application-mobile .navbar-brand span,
.application-mobile .nav-item a,
.application-mobile .nav-item span,
.application-mobile div {
    font-size: 12px !important;
}

.application-mobile svg.icon-option-primary {
    width: 16px;
    height: 16px;
}
 
.application-mobile svg.icon-option-secondary {
    width: 10px;
    height: 10px;
}
 
.application-desktop,
.application-desktop .form-control,
.application-desktop .dropdown-toggle,
.application-desktop .form-select,
.application-desktop .btn-sm,
.application-desktop .navbar-brand span,
.application-desktop .nav-item a,
.application-desktop .nav-item span,
.application-desktop div {
    font-size: 14px !important;
}

.application-desktop svg.icon-option-primary {
    width: 20px;
    height: 20px;
}

.application-desktop svg.icon-option-secondary {
    width: 12px;
    height: 12px;
}*/
 
 /* Estilos personalizados */
 /* Desactiva los efectos en los estados focus, active, hover */
/* Estilos personalizados */
.btn-custom-primary {
    background-color: #287233; /* Verde suave */
    color: white; /* Color de texto que permanece igual */
    border: 2px solid #1F5C2A; /* Bordes del mismo color */
    transition: background-color 0.3s ease; /* Suaviza la transición */
    min-width: 120px;
    padding: 8px 16px;
    font-size: 16px;
}

.btn-custom-primary:hover {
    background-color: #1F5C2A; /* Verde ligeramente más oscuro */
    color: white; /* Color de texto permanece igual */
}

.btn-custom-primary:disabled {
    background-color: #a6c3ab; /* Verde ligeramente más oscuro */
    color: white; /* Color de texto permanece igual */
}

.btn-custom-outline {
    background-color: transparent;
    border: 2px solid #287233;
    color: #287233; /* Verde más oscuro */
    transition: background-color 0.3s ease, color 0.3s ease; /* Suaviza la transición */
    min-width: 120px;
    padding: 8px 16px;
    font-size: 16px;
}

.btn-custom-outline:hover {
    background-color: #e5efe9; /* Verde suave */
    color: #287233; /* El color del texto se mantiene igual que el del fondo */
    border: 2px solid #287233;
}

.btn-custom-secondary {
    background-color: #6f6f6f; /* Gris claro */
    color: white; /* Color de texto que permanece igual */
    border: 2px solid #6f6f6f; /* Bordes del mismo color */
    transition: background-color 0.3s ease; /* Suaviza la transición */
    min-width: 120px;
    padding: 8px 16px;
    font-size: 16px;
}

.btn-custom-secondary:hover {
    background-color: #5c5c5c; /* Gris más oscuro en hover */
    border-color: #5c5c5c; /* Bordes más oscuros en hover */
    color: white; /* Color de texto permanece igual */
}

.btn-custom-delete {
    background-color: #dc3545; /* Rojo Bootstrap */
    color: white; /* Color de texto que permanece igual */
    border: 2px solid #dc3545; /* Bordes del mismo color */
    transition: background-color 0.3s ease; /* Suaviza la transición */
    min-width: 120px;
    padding: 8px 16px;
    font-size: 16px;
}

.btn-custom-delete:hover {
    background-color: #c82333; /* Rojo más oscuro */
    color: white; /* Color de texto sigue siendo blanco */
}

.btn-custom-delete:disabled {
    background-color: #c82333; /* Rojo más oscuro */
    border: 2px solid #dc3545; /* Bordes del mismo color */
    color: white; /* Color de texto sigue siendo blanco */
}

/* Estilo para el enlace */
    
.custom-link {
    color: #1E90FF;
    text-decoration: none;
    font-weight: normal; /* Cambiado a normal para menos bold */
}

.custom-link:hover {
    text-decoration: underline;
    color: #4682B4; /* Un tono más oscuro del azul */
}


a {
    color: #1E90FF;
    text-decoration: none;
}
a:hover {
    color: #4682B4;
    text-decoration: underline;
}

.nav-link {
    color: #1E90FF;
}

.nav-link:hover {
    color: #4682B4;
    text-decoration: underline;
}

.nav-link.active {
    color: #4682B4 !important;
    font-weight: bold !important;
}
  
.modal-title {
    font-weight: bold;
}

.image-application{
    height: 50px;
}

/* Estilos base para texto y elementos */
p, a, li, button, input, textarea, select, option, label, 
.form-control, .btn, .accordion-button, .bot-message {
    font-size: 16px;
}

h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.75rem; }
h4 { font-size: 1.5rem; }
h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }

/* Media query para pantallas medianas (≥576px y <768px) */
@media (max-width: 767.98px) {
    .btn-custom-primary,
    .btn-custom-outline,
    .btn-custom-secondary,
    .btn-custom-delete {
        min-width: 100px;
        padding: 6px 12px;
        font-size: 14px;
    }

    .image-application {
        height: 40px;
    }

    body {
        font-size: 14px;
    }

    p, a, li, button, input, textarea, select, option, label,
    .form-control, .btn, .accordion-button, .bot-message {
        font-size: 14px !important;
    }

    h1 { font-size: 2rem !important; }
    h2 { font-size: 1.75rem !important; }
    h3 { font-size: 1.5rem !important; }
    h4 { font-size: 1.25rem !important; }
    h5 { font-size: 1.1rem !important; }
    h6 { font-size: 1rem !important; }
}

/* Media query para pantallas pequeñas (<576px) */
@media (max-width: 575.98px) {
    .btn-custom-primary,
    .btn-custom-outline,
    .btn-custom-secondary,
    .btn-custom-delete {
        min-width: 90px;
        padding: 4px 8px;
        font-size: 13px;
    }

    .image-application {
        height: 35px;
    }

    body {
        font-size: 13px;
    }

    p, a, li, button, input, textarea, select, option, label,
    .form-control, .btn, .accordion-button, .bot-message {
        font-size: 13px !important;
    }

    h1 { font-size: 1.75rem !important; }
    h2 { font-size: 1.5rem !important; }
    h3 { font-size: 1.25rem !important; }
    h4 { font-size: 1.1rem !important; }
    h5 { font-size: 1rem !important; }
    h6 { font-size: 0.9rem !important; }

    .accordion-button::after {
        background-size: 13px;
    }
}