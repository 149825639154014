/* Contenedor del mensaje */
.message-wrapper {
  margin-bottom: 1rem;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
}

/* Encabezado de fecha */
.date-header-container {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.date-header {
  background-color: #f1f5f9;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  color: #64748b;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.date-header:hover {
  background-color: #e2e8f0;
  transform: translateY(-1px);
}

/* Burbujas de mensaje */
.message-container {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
}

.user-container {
  align-self: flex-end;
}

.message-bubble {
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  position: relative;
  word-break: break-word;
}

/* Estilos para mensajes del usuario */
.user-message {
  margin-left: auto;
  background-color: #3b82f6;
  color: white;
  border-bottom-right-radius: 0.25rem;
}
 
/* Estilos para mensajes del asistente */
.assistant-message {
  margin-right: auto;
  background-color: #e2e8f0;
  color: #1e293b;
  border-bottom-left-radius: 0.25rem;
}
  
/* Footer del mensaje */
.message-footer {
  display: flex;
  margin-top: 0.25rem;
  padding: 0 0.5rem;
  font-size: 0.75rem;
  color: #64748b;
}

.message-footer.justify-end {
  justify-content: flex-end;
}

.message-footer.justify-start {
  justify-content: flex-start;
}

.timestamp-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.timestamp {
  font-size: 0.75rem;
  color: #94a3b8;
}

.report-button {
  background: none;
  border: none;
  color: #64748b;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 0.25rem;
}

.report-button:hover {
  color: #475569;
  background-color: #f1f5f9;
}

/* Animaciones */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media queries */
@media (max-width: 640px) {
  .message-container {
    max-width: 85%;
  }
  
  .message-bubble {
    padding: 0.5rem 0.75rem;
  }
  
  .date-header {
    font-size: 0.75rem;
    padding: 0.375rem 0.75rem;
  }
}

/* Efectos de hover */
.message-bubble {
  transition: all 0.2s ease;
}

.message-bubble:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Estilos para mensajes con error */
.error-message {
  background-color: #fee2e2;
  color: #991b1b;
  border-left: 4px solid #dc2626;
}

/* Estilos para mensajes del sistema */
.system-message {
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  color: #475569;
  text-align: center;
  margin: 1rem auto;
  max-width: 90%;
} 