/* Contenedor principal */
.payu-container {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .payu-title {
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Sección de Beneficios */
  .benefits-section {
    max-width: 700px;
    margin: 0 auto 30px;
    background: #f8f9fa;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Beneficios alineados */
  .payu-benefits-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }
  
  .payu-benefits-list li {
    display: flex;
    align-items: center;
    gap: 8px;
    text-align: left;
  }
  
  .feature-icon {
    color: #287233 !important;
    flex-shrink: 0;
  }
  
  /* Primer beneficio con ancho completo */
  .payu-benefits-list .full-width {
    grid-column: span 2;
    font-weight: bold;
    font-size: 18px;
    color: #287233;
    padding: 12px;
    background: rgba(40, 114, 51, 0.1);
    border-radius: 8px;
    text-align: center;
  }
  
  /* Tarjetas de Pago */
  .pricing-container {
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  
  /* Tarjeta de precio */
  .pricing-card {
    flex: 1;
    min-width: 250px;
    background: white;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 25px 20px;
    transition: transform 0.3s ease-in-out;
    position: relative;
  }
  
  .pricing-card:hover {
    transform: translateY(-5px);
  }
  
  .pricing-card .card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  /* Plan anual destacado */
  .annual-plan {
    border: 3px solid #287233;
    z-index: 1;
  }
  
  /* Badge de "Mejor Opción" */
  .recommended-badge {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: #287233;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 12px;
    border-radius: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
    z-index: 2;
  }
  
  /* Plan mensual */
  .monthly-plan {
    border: 2px solid #ddd;
  }
  
  /* Precios */
  .price {
    font-size: 32px;
    font-weight: bold;
    color: #333;
    margin: 15px 0;
  }
  
  .currency {
    font-size: 24px;
    vertical-align: top;
    color: #287233;
  }
  
  .period {
    font-size: 16px;
    color: #666;
  }
  
  /* Alertas de estado */
  .alert {
    padding: 12px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
  }
  
  .alert-success {
    background: #d4edda;
    color: #155724;
  }
  
  .alert-danger {
    background: #f8d7da;
    color: #721c24;
  }

  /* CSS para los tabs */
.tabs-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tab-button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    background-color: #ddd;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.tab-button.active {
    background-color: #287233;
    color: white;
    font-weight: bold;
}

.plan-details {
    text-align: center;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.price {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
}

.currency {
    font-size: 18px;
    color: #287233;
}

.period {
    font-size: 14px;
    color: #666;
}

  
  /* Responsividad */
  @media (max-width: 768px) {
    .payu-benefits-list {
      grid-template-columns: 1fr;
    }
    
    .payu-benefits-list .full-width {
      grid-column: 1;
    }
    
    .pricing-container {
      flex-direction: column;
      align-items: center;
    }
    
    .pricing-card {
      width: 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }
  }

.payu-submit-button {
    width: 100%;
    padding: 12px 24px;
    background-color: #00a1df;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.payu-submit-button:hover {
    background-color: #0086b8;
}

.payu-submit-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 161, 223, 0.3);
}

.payu-submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.form-error {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
}