/* Pricing Section Styles */
.pricing-section {
    background-color: #f8f9fa;
    padding: 80px 0;
}

.pricing-header {
    margin-bottom: 2rem;
}

.pricing-header-title {
    font-size: 3.6rem;
    font-weight: 800;
    color: #1a1a1a;
    letter-spacing: -0.5px;
    margin-bottom: 1.5rem;
    line-height: 1.2;
    text-align: center;
    text-transform: none;
}

.pricing-header-description {
    font-size: 1.6rem;
    line-height: 1.6;
    color: #2c2c2c;
    font-weight: 500;
    margin-bottom: 1.5rem;
    letter-spacing: 0.2px;
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.pricing-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #e9ecef;
    border-radius: 12px;
}

.pricing-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.pricing-card-highlighted {
    border: 2px solid var(--primary-color);
    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
}

.card-title {
    color: var(--primary-color);
    font-weight: 600;
}

.pricing-value {
    border-bottom: 1px solid #e9ecef;
    padding-bottom: 1rem;
}

.currency {
    font-size: 1.2rem;
    font-weight: 600;
    vertical-align: top;
}

.price {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--primary-color);
}

.period {
    font-size: 0.9rem;
    color: #6c757d;
}

.feature-icon {
    color: var(--primary-color);
    flex-shrink: 0;
}

.feature-text {
    line-height: 1.4;
}

.card-footer {
    background: transparent;
    padding: 1rem 1.5rem;
}

.btn-custom-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: white;
    transition: all 0.3s ease;
}

.btn-custom-primary:hover {
    background-color: var(--primary-color-dark);
    border-color: var(--primary-color-dark);
    color: white;
    transform: translateY(-1px);
}

.text-muted {
    font-size: 1.4rem;
    line-height: 1.6;
    color: #2c2c2c !important;
    font-weight: 500;
    letter-spacing: 0.2px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .pricing-card-highlighted {
        transform: none;
    }
    
    .pricing-card:hover {
        transform: translateY(-5px);
    }

    .pricing-section {
        padding: 40px 0;
    }

    .price {
        font-size: 2.5rem;
    }

    .pricing-header-title {
        font-size: 2.8rem;
    }
    
    .pricing-header-description {
        font-size: 1.3rem;
    }

    .section-title {
        font-size: 2.5rem;
    }
    
    .text-muted {
        font-size: 1.2rem;
    }
}
 
.landing-plans_btn-full-width {
    width: 100%;
    padding: 0.6rem;
}

@media (max-width: 768px) {
    .section-title {
        font-size: 2rem;
    }
}

.feature-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0.5rem 0;
}

.feature-list li {
    padding: 0.3rem;
}
 