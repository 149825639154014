.books-container {
    padding: 4rem 2rem;
    background-color: #f8f9fa;
}
 
.section-title {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 3rem;
    font-size: 2.5rem;
}

.books-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.book-card {
    background: white;
    border-radius: 10px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.book-card:hover {
    transform: translateY(-5px);
}

.book-link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.book-cover-container {
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 1rem;
    position: relative;
}

.book-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.amazon-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 87, 61, 0.9);
    color: white;
    padding: 10px;
    transform: translateY(100%);
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.book-cover-container:hover .amazon-overlay {
    transform: translateY(0);
}

.book-cover-container:hover .book-cover {
    transform: scale(1.05);
}

.amazon-text {
    font-weight: 500;
}

.amazon-icon {
    font-style: normal;
}

.book-title {
    color: #00573d;
    font-size: 1.25rem;
    margin: 1rem 0;
}

.book-description {
    color: #666;
    font-size: 0.9rem;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .books-grid {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .section-title {
        font-size: 2rem;
    }
}