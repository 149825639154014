/* Mensaje de advertencia */
.unsubscribe-warning {
    background: #fff3cd;
    color: #856404;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.warning-icon {
    margin-right: 10px;
}

/* Caja de texto para la razón de cancelación */
.unsubscribe-reason {
    margin: 20px 0;
    text-align: left;
}

.unsubscribe-reason label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
    color: #444;
}

.unsubscribe-reason textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    resize: none;
    transition: border 0.3s ease-in-out;
}

.unsubscribe-reason textarea:focus {
    border-color: #287233;
    outline: none;
}

/* Botón de cancelar suscripción */
.unsubscribe-button {
    background: #d9534f;
    color: #fff;
    font-size: 1.2rem;
    padding: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.unsubscribe-button:hover {
    background: #c9302c;
}

.unsubscribe-button:disabled {
    background: #bbb;
    cursor: not-allowed;
}

/* Mensaje de éxito */
.unsubscribe-success {
    padding: 20px;
    background: #eafaf1;
    color: #287233;
    border-radius: 10px;
    text-align: center;
}

.success-icon {
    margin-bottom: 10px;
}

.unsubscribe-success h2 {
    font-size: 1.6rem;
    font-weight: bold;
}

.unsubscribe-success p {
    font-size: 1rem;
    color: #444;
}