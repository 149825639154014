.landing-signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 200px); /* Ajustado para considerar el header y footer */
    padding: 20px;
}

.landing-signin-box {
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.landing-signin-box h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #287233;
}

.landing-signin-box .form-control {
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
}

.landing-signin-box .form-control:focus {
    border-color: #287233;
    box-shadow: 0 0 0 0.2rem rgba(40, 114, 51, 0.25);
}

.landing-signin-box .btn-primary {
    padding: 10px;
    font-weight: 500;
    transition: background-color 0.3s ease;
}

.landing-signin-box .btn-primary:hover {
    background-color: #1e5627 !important;
}

.landing-signin-box a {
    color: #287233;
    text-decoration: none;
    transition: color 0.3s ease;
}

.landing-signin-box a:hover {
    color: #1e5627;
    text-decoration: underline;
}

.landing-signin-divider {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 15px 0;
}

.landing-signin-divider::before,
.landing-signin-divider::after {
    content: '';
    flex: 1;
    height: 1px;
    background-color: #dee2e6;
}

.landing-signin-divider span {
    padding: 0 15px;
} 