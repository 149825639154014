 .subscription-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: center;
}

.subscription-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #f8f9fa;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.detail-item {
    padding: 12px;
    background-color: white;
    border-radius: 8px;
    margin: 0;
    transition: transform 0.3s ease-in-out;
}

.detail-item:hover {
    transform: translateY(-2px);
}

.detail-label {
    color: #444;
    font-size: 1rem;
}

.subscription-status {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #dee2e6;
}

.auto-renewal-enabled {
    color: #287233;
    font-weight: 600;
    text-align: center;
    background: #eafaf1;
    padding: 15px;
    border-radius: 8px;
}

.cancelled-subscription {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.cancelled-message {
    color: #d9534f;
    font-weight: 600;
    background: #f8d7da;
    padding: 15px;
    border-radius: 8px;
    width: 100%;
    text-align: center;
}

.valid-until {
    color: #856404;
    background: #fff3cd;
    padding: 15px;
    border-radius: 8px;
    width: 100%;
    text-align: center;
}

.reactivate-button {
    background-color: #00a1df;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 300px;
}

.reactivate-button:hover {
    background-color: #0086b8;
}

.reactivate-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 161, 223, 0.3);
}

.reactivate-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    border-radius: 8px;
    margin-top: 1rem;
    text-align: center;
    width: 100%;
    font-weight: 600;
}

@media (max-width: 768px) {
    .subscription-container {
        margin: 20px;
        padding: 20px;
    }
    
    .subscription-title {
        font-size: 1.5rem;
    }

    .subscription-details {
        padding: 15px;
    }
}
