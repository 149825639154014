.fixed-simple-header {
     
    top: 55px;
    z-index: 1;
    /* margin: 0px -10px; */
}

.before-fixed-simple-header {
    /* position: sticky; */
    top: 45px;
    z-index: 1;
}

.simple-header {
    margin-bottom: 7px;
}

.text-element {
    font-size: 1rem; /* Tamaño normal */
    font-weight: 400; /* Peso regular */
    color: #6c757d; /* Gris opaco (bootstrap gray-600) */
    border-bottom: 1px solid #d6d6d6; /* Gris claro */
    padding-bottom: 0.25rem; /* Espaciado inferior para separar del contenido */
    display: inline-block; /* Asegura que el borde solo ocupe el ancho del texto */
}