.title-container {
    padding: 12px;
    margin-bottom: 16px;
  }
  
  .title-border {
    width: 4px;
    height: 24px;
    background-color: #0277BD;
    margin-right: 12px;
    border-radius: 2px;
  }
  
  .title-text {
    color: #0277BD;
    font-weight: 500;
    font-size: 16px;
  }