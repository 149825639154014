span.material-icon {
    display: inline-block;
    border-radius: 3px;
    border: 1px solid #AAAAAA;
}

.material-icon-container {
    border-radius: 1px;
    position: relative;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center; /* Horizontal centering */
    align-items: center;     /* Vertical centering */
    
}

.material-icon-holder {
   
}

.material-icon-secondary {
    position: absolute;
    top: 0;
}

.icon-option-secondary {
    width: 12px;
    height: 12px;
}

.icon-option-primary {
    width: 20px;
    height: 20px;
}