.chat-container {
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
}

.chat-messages {
    flex-grow: 1;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #dee2e6 transparent;
}

.chat-messages::-webkit-scrollbar {
    width: 6px;
}

.chat-messages::-webkit-scrollbar-thumb {
    background-color: #dee2e6;
    border-radius: 3px;
}

.message-bubble {
    padding: 1rem;
    border-radius: 16px;
    font-size: 0.95rem;
    line-height: 1.4;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Mensajes de usuario */
.user-message {
    background: none; /* Fondo transparente */
    color: #287233; /* Color principal de la aplicación */
    text-align: right;
    border: 1px solid #287233; /* Opcional: añadir un borde */
}

/* Mensajes del bot */
.bot-message {
    background: none; /* Fondo transparente */
    color: #333; /* Color neutro */
    text-align: left;
    border: 1px solid #dee2e6; /* Opcional: añadir un borde */
}

/* Mensajes de error */
.error-message {
    background: none; /* Fondo transparente */
    color: #dc3545; /* Color de error */
    text-align: left;
    border: 1px solid #dc3545; /* Opcional: añadir un borde */
}

.typing-indicator {
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
}

.typing-indicator span {
    width: 5px;
    height: 5px;
    background-color: #6c757d;
    border-radius: 50%;
    animation: typing 1.5s infinite;
}

.typing-indicator span:nth-child(2) {
    animation-delay: 0.3s;
}

.typing-indicator span:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes typing {
    0%, 100% {
        transform: scale(0.8);
        opacity: 0.6;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
}

.chat-input-container {
    background-color: #fff;
    border-top: 1px solid #dee2e6;
}

.info-icon {
    position: absolute;
    right: 50px; /* Ajusta este valor según la distancia al botón de cerrar */
    top: 29px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #6c757d; /* Color similar al botón de cerrar */
}
.info-icon:hover {
    color: #000; /* Oscurecer en hover */
}