.dashboard-section-show {
    border: solid 1px rgb(220, 220, 220);
    padding: 10px;
}

.dashboard-section .edit-content:hover {
    border-color: blue;
}

.dashboard-section .edit-content {
    min-height: 200px;
    border: solid 1px rgb(220, 220, 220); 
    padding: 10px;
}
 
.dashboard-section .view-content {
    min-height: 200px;
    border: solid 1px rgb(220, 220, 220);
    border-radius: 5px; 
    padding: 10px;
}

