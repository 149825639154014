.payment-container {
    margin-top: 120px;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.payment-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
    font-weight: bold;
}

.complete-purchase-title {
    font-size: 1.75rem;
    color: #555;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

.payment-details {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 30px;
}

.payment-details p {
    margin: 10px 0;
}

.divider {
    border-top: 2px solid #dee2e6;
    width: 50%;
    margin: 20px auto;
}

.text-center {
    text-align: center;
}

.paypal-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #0070ba;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 20px;
}

.paypal-button:hover {
    background-color: #005ea6;
}