 .page-action-bottoms {
    /* bottom: 0px;
    position: fixed;
    z-index: 150;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100vw;
    margin: 0px 0px 5px -5px; 
    background-color: rgba(240,240,240,0.9)!important;
    border-bottom: 2px solid #287233;
    border-top: 2px solid #287233; */
    border-top: 2px solid #287233;
}

.page-edit-container {
    /* margin-bottom: 80px; */
}

.page-not-edit-container {
    padding-bottom: 20px; 
}