.authenticated-container-margin-top {
    margin-top: 80px;
}

.fixed-authenticated-body {
    padding-right: 5px;
    padding-left: 5px;
    width: 100%;
}
 
.accordion-button:not(.collapsed) {
    /*background-color: #287233; *//* Color de fondo */
    background-color: #f1f1f1;
    color: #287233;             /* Color del texto */
    /* font-weight: 600; */
}
 
.menu-text {
    text-transform: uppercase;
}

.nav-menu-item-container {  
    text-decoration: none;
  
    padding-bottom: 0px; /* the submenu wont go hide unexpectly */
}
 
.config-menu-item-container .dropdown-menu {
    left: -180px;
}

@media (max-width: 768px) {
    .config-menu-item-container .dropdown-menu {
        left: -110px;
    }
}

.config-menu-item-container .dropdown-toggle::after {
    display: none;
}
  
.menu-item-container {
    padding-left: 15px;
}

/* Custom CSS to override NavDropdown submenu anchor styling */
.navbar .nav-item .nav-link {
    color: #287233; 
    /* font-weight: 600; */
    text-decoration: none; /* Remove underline if present */
}
  
a.navbar-brand span.menu-item-submenu {
    color: #287233;
    font-weight: 400;
    font-size: 14px;
}

a .menu-item-submenu:hover {
    /* font-size: 600 */
}

.alias_text-large {
    font-size: 1rem; /* Aumenta el tamaño del texto */
    line-height: 1; /* Ajusta la altura de línea para alinearlo con el icono */
}
 
i.menu-item-size {
    font-size: 14px;
}
  
.navbar .dropdown-open:hover .dropdown-menu {
    display: block !important;
    
}
 
.navbar .nav-item .dropdown-menu { 
    position: absolute;   /* forzado, en caso de que algo lo haya sobrescrito */
    
}
   
.authenticated-container {
    display: flex;
    flex-direction: column;
     
}
  
.row-container {
    display: flex;
    flex: 1;
    
}
   
@media (max-width: 768px) { 
    .row-container {
        
    }
}

@media (max-width: 768px) { 
    .body-container {
        margin-left: 0; 
    }
}
 
.side-header-container {  
    
    margin-top: 50px;
    
    width: 40px;
    bottom: 0px;
    padding-left: 6px!important;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0; 
    overflow-y: auto; /* Para permitir el scroll dentro del menú si es necesario */
}
 
.dropdown-toggle {
    /* color: white; */
    font-weight: 500;
}

.dropdown-toggle:hover {
    color: #f1f1f1;
}

.dropdown-toggle-plus {
    /* color: white; */
    font-weight: 500;
}
  
.custom-top-header {
    /* background-color: #e4eae5; */
    border-bottom: 2px solid #287233;
    padding-top: 1px;
    padding-bottom: 1px;
}
  
.custom-side-menu-separator {
    margin: 5px 0px 5px 0px;
    color: white;
}
  
.custom-side-menu-trigger:hover {
    cursor: pointer;
}
  
.customTooltip .tooltip-inner {
    background-color: #f1f1f1;
    color: black;
    font-weight: 500;
}

.customTooltip .tooltip-arrow::before {
    border-right-color: #f1f1f1 !important;
    border-bottom-color: #f1f1f1 !important;
}

.custom-list-group-item-action {
    padding-top: 15px;
}
  
.custom-side-top-header {
    /* display: flex;
    flex-direction: row; */
}
 
.disabled-link {
    pointer-events: none;
    cursor: not-allowed;
    color: gray;
    text-decoration: none;
}
footer.main-menu {
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 2px;
}

div.footer-supporter {
    padding: 25px;
}