/* Estilos generales */
 

/* Watermark */
.watermark {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../logo.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;
    opacity: 0.03;
    z-index: 0;
    pointer-events: none;
}

/* Container principal */
.container-fluid {
    flex: 1;
    z-index: 1;
    padding: 0;
   
    margin: 0 auto;
}

/* Footer */
.footer {
    background-color: #287233;
    color: white;
    padding: 40px 0;
    margin-top: 50px;
}

.footer-links-section {
    display: flex;
    justify-content: center;
    gap: 100px;
    margin-bottom: 60px;
    padding: 0 20px;
}

.footer-links-column {
    display: flex;
    flex-direction: column;
}

.footer-links-column h4 {
    color: #333;
    margin-bottom: 20px;
    font-weight: 600;
}

.footer-links-column a {
    color: #666;
    text-decoration: none;
    margin-bottom: 10px;
    transition: color 0.3s ease;
}

.footer-links-column a:hover {
    color: #333;
}

.footer-content {
    border-top: 1px solid #ddd;
    padding-top: 30px;
}

.footer-content-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.footer a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer a:hover {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: underline;
}

.separator {
    color: #dee2e6;
    margin: 0 0.5rem;
}

.social-media-links {
    display: flex;
    gap: 15px;
    align-items: center;
}

.social-media-links a {
    color: #ffffff;
    transition: color 0.3s ease;
}

.social-media-links a:hover {
    color: #333;
}

.footer .social-media-links a svg path {
    fill: white;
}

/* Animaciones */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Clases de utilidad */
.fade-in {
    animation: fadeIn 0.5s ease-out forwards;
}

/* Media Queries */
@media (max-width: 768px) {
    .container-fluid {
        padding: 0 1rem;
    }

    .footer-content {
        flex-direction: column;
        text-align: center;
        padding: 1rem;
    }

    .separator {
        display: none;
    }

    .social-media-links {
        margin-top: 1rem;
    }
}

@media (max-width: 576px) {
    .watermark {
        background-size: 90%;
    }

    .footer {
        padding: 1rem 0;
    }
} 
.section-title {
    text-align: center;
    color: #00573d;
    margin-bottom: 3rem;
    font-size: 2.8rem;
    font-weight: 700;
    letter-spacing: 1px;
    position: relative;
    padding-bottom: 15px;
}

/* Transiciones suaves */
.page-transition {
    transition: all 0.3s ease-in-out;
}

/* Estilos para el contenedor de las secciones principales */
.main-container {
    padding: 2rem 0;
    animation: fadeIn 0.5s ease-out;
}

/* Mejoras en la accesibilidad */
@media (prefers-reduced-motion: reduce) {
    .fade-in,
    .page-transition {
        animation: none;
        transition: none;
    }
}

/* Add or modify these styles */
.nav-links {
    display: flex;
    gap: 1rem; /* Reduces space between menu items */
    margin-right: auto; /* Pushes menu items to the left */
}

.nav-links a {
    padding: 0.5rem 0.75rem; /* Adjust padding to bring items closer */
}